import Route, {
  MultiRoute,
  RoutesConfiguration,
  RoutesSection,
} from '@models/types/Routes';
import { ServerConfig } from '@models/types/ServerConfig';
import routesConfig from '@router/configuration/Routes';
import { lazyOutletRouteFromRoute } from '@router/LazyRouteElement';
import { matchPath } from 'react-router-dom';

export const allRoutes = (configuration: RoutesConfiguration): Route[] => {
  return configuration.flatMap(allRoutesInSection);
};

export const allRoutesInSection = (section: RoutesSection): Route[] => {
  return section.routes.flatMap((route) => {
    if ((route as MultiRoute).routes) {
      return (route as MultiRoute).routes.map((subRoute) => {
        return { parentRootRouteId: route.id, ...subRoute } as Route;
      });
    } else {
      return route as Route;
    }
  });
};

export const checkRoutePermission = (
  route: Route | MultiRoute,
  permissions: string[],
): boolean => {
  return (
    !route.requiredPermission || permissions?.includes(route.requiredPermission)
  );
};

export const getMatchedSection = (
  routes: Route[],
  pathname: string,
  permissions: string[],
  section: RoutesSection,
): RoutesSection | undefined => {
  for (const route of routes) {
    const match = matchPath(route.path, pathname);

    if (!match) {
      continue;
    }

    if (!checkRoutePermission(route, permissions)) {
      // If matched route is unavailable for user, continue looking in other features
      continue;
    }

    return section;
  }
};

export const getSectionForPathNameInConfiguration = (
  pathname: string,
  configuration: RoutesConfiguration,
  permissions: string[],
): RoutesSection | undefined => {
  for (const section of configuration) {
    const featureRoutes = allRoutesInSection(section)
      .filter((route) => route.path !== '*')
      .filter((route) => pathname.startsWith(route.path));

    for (const featureRoute of featureRoutes) {
      const subfeatureRoutes = [featureRoute, ...(featureRoute.children ?? [])];
      const matchedSection = getMatchedSection(
        subfeatureRoutes,
        pathname,
        permissions,
        section,
      );

      if (matchedSection) {
        return matchedSection;
      }
    }
  }
  return undefined;
};

export const checkRouteServerSupport = (
  route: Route | MultiRoute,
  serverConfig: ServerConfig | undefined,
  isAdmin: boolean,
) => {
  if (!route.serverSupportVerifier) {
    return true;
  } else {
    return route?.serverSupportVerifier(serverConfig, isAdmin);
  }
};

export const getRouteForPathnameInConfiguration = (
  pathname: string,
  configuration: RoutesConfiguration,
  permissions: string[],
  serverConfig: ServerConfig | undefined,
  isAdmin: boolean,
): Route | undefined => {
  const routes = allRoutes(configuration)
    .filter((route) => route.path !== '*')
    .filter((route) => pathname.startsWith(route.path))
    .flatMap((route) => [route, ...(route.children ?? [])]);
  for (const route of routes) {
    const match = matchPath(route.path, pathname);
    if (!match) {
      continue;
    }

    if (!checkRoutePermission(route, permissions)) {
      // If matched route is unavailable for user, continue looking in other features
      break;
    }

    if (!checkRouteServerSupport(route, serverConfig, isAdmin)) {
      break;
    }
    return route;
  }
  return undefined;
};

export const getMainPageOutletRoutes = () => {
  return getMainPageOutletRoutesWithConfiguration(routesConfig);
};

export const getMainPageOutletRoutesWithConfiguration = (
  configuration: RoutesConfiguration,
) => {
  const routes = allRoutes(configuration)
    .flatMap((route: Route) => {
      if (!route.children) {
        return [route];
      }

      return [route, ...route.children];
    })
    .map((route) => lazyOutletRouteFromRoute(route));

  return routes;
};

export const getOptionIdFromPath = (path?: string): string | undefined => {
  return path?.split('/')[1];
};
