import { ClusterOutlined } from '@ant-design/icons';
import PATH from '@models/constants/Path';
import Route from '@models/types/Routes';
import { lazy } from 'react';

const LazyTrustListListPage = lazy(
  () =>
    import(
      '@pages/dashboard/trustList/TrustListListPage/TrustListListPage.adapter'
    ),
);

const LazyTrustListDetailsPage = lazy(
  () =>
    import(
      '@pages/dashboard/trustList/TrustListDetailsPage/TrustListDetailsPage.adapter'
    ),
);

const trustListRoute: Route = {
  children: [
    {
      component: LazyTrustListDetailsPage,
      id: 'trust-list-details',
      path: PATH.TrustList.Details(':trustEntityId'),
    },
  ],
  component: LazyTrustListListPage,
  hideInSidebar: false,
  iconComponent: ClusterOutlined,
  id: 'trust-list',
  path: PATH.TrustList.List,
  serverSupportVerifier: (_, isAdmin) => isAdmin,
  title: 'route.trustList.title',
};

export default trustListRoute;
